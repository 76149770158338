.SearchBar {
  display: flex;
  align-items: center;
  background-color: #f0f3f5;
  height: 49px;
  border-bottom: 2px solid white;
}
.Input {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
.Input > .MuiSvgIcon-root {
  padding: 0px !important;
}
.Input > input {
  border: none;
  cursor: text;
  background-color: #ffffff;
  color: #b1b3b5;
  padding-left: 10px;
  width: 80%;
  font-size: 15px;
}
.Input > input:focus {
  outline: none;
  color: #b1b3b5;
  font-size: 15px;
}
