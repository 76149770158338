/********Counter Offerr after accept Expired Button Color ***********/
.buttonColorRed {
    border: 1px solid #c90e0e !important;
    color: #c90e0e !important;
    width:max-content;
  }
/********Counter Offer accept Button Color***************/  
  .buttonColorGreen {
    border: 1px solid #0ee53d !important;
    color: #0ee53d !important;
    width:max-content;
  }