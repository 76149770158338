.avatar {
    color:blue
}
.deletedLoad{
    color: red;
    margin-left: 5px !important;
}

.red {
    color: red;
}