.content{
        background: url("https://loadeo-all-uploads.s3.us-west-2.amazonaws.com/loadeo-develop/loadeo-general-docs/Loadeo_pricing_1920x550.jpg") center
          center/cover no-repeat local;
        background-size: 100% 100%;
}
.imgsize{
    margin-bottom: 5%;
    padding-left: 15%;
  }
  .btncolor{
      width: max-content !important;
      color: white !important;
  }