.home {
  display: flex;
}

@media (min-width: 570px) and (max-width:820px) {
  .loadexp{
    display:block !important;
  }
}

/* counter offer style */
.counterOfferSubmitBtn{
  border: 2px solid green;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
  margin-right:3%;
}
.counterOfferSubmitBtn:hover{
  background-color: green;
  color:white;
}
.counterOfferSubmitBtn:focus{
  outline: none !important;
}
.counterOfferCancelBtn:hover{
  background-color: red;
  color:white;
}
.counterOfferCancelBtn:focus{
  outline: none !important;
}
.counterOfferCancelBtn{
  border: 2px solid red;
  border-radius: 5px;
  padding: 5px 8px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;

}
.counterOfferAcceptBtn:hover{
  background-color: orange;
  color:white;
}
.counterOfferAcceptBtn:focus{
  outline: none !important;
}
.counterOfferAcceptBtn{
  border: 2px solid orange;
  border-radius: 5px;
  width:max-content;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  background-color: white;
}
 
.afterBidDisplay {
  margin-bottom: 0px !important;
  font-size: 14px !important;
  color:#101e35;
}

.boxColor {
  background-color: rgb(232 240 254);
}

.heightFix
{
  width: 100%;
  background-color: #ececec;
  height: 154px;
}
.h6{
color: #101e35;
}