.aligntemOfStatus {
    padding-right: 12% !important;
}

#status {
    padding: 0px !important
}

#driver {
    padding: 3%
}