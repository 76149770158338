.headerChat {
    background-color: #0d2346;
    height: 59px;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
  }
  .headerChatLeft {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .title {
    padding-left: 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color:white !important;
    font-weight: bold;
  }
   .titleMcNumber {
    font-weight: 400;
    font-size: 15px;
    line-height: 21px;
    color:white !important;
    font-weight: bold;
  }
  .headerChatRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 6vw;
  }
  .icon {
    cursor: pointer;
    padding: 8px;
    height: 24px;
    width: 24px;
    margin-left: 10px;
  }
  
  .icon:active {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
  .icon:first-of-type {
    margin-left: 0px;
  }
  
  @media only screen and (min-width: 800px) and (max-width: 900px)  {
    .Title{
    font-weight: 300;
    font-size: 10px;
    }
    .TitleMcNumber{
    font-weight: 300;
    font-size: 10px;
    }
    
    }
    @media only screen and (max-width: 830px)  {
      .Title{
      font-weight: 250;
      font-size: 7px;
      }
      .TitleMcNumber{
      font-weight: 250;
      font-size: 7px;
      }
     
      }