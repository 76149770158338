

.mapmarkerdiv{
    position:relative;
    padding: 2% 3%;
    z-index:0;
}

.mapmarkerA{
    position: absolute;
    top: 22%;
    right: 2% !important;
    text-align: center;
    cursor:pointer;
    max-width: 7%;
    bottom: 70% !important;
}
.mapmarkerB{
    position: absolute;
    top: 31%;
    right: 2% !important;
    max-width: 7%;
    text-align: center;
    cursor: pointer;
}
.markerImage {
    max-width: 85% !important;
}