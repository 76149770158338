.gridStyle {
    width: 100%;
    height: 5%;
}

.rowStyle {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

#center {
    position: relative;
    z-index: 1;
    width:80%;
    margin-bottom: 10.5%;
    margin-top: 8.2%;
}

.rc-anchor-normal {
    width: 200% !important;
    margin-left: 10% !important;
}

.headerDiv {
    color: white !important;
    font-family:Cambria !important;
    padding: 10px 0px 10px 20px !important;
    font-size: 20px;
}

.feedbackButton {
    margin: 0px;
    font-weight: bold;
    color: white !important;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}