.agreementDiv {
display: flex !important;
width: 100%;
}

.paragraphyStyle {
text-align: justify;
}

.agreementBox {
    width: 100%;
    text-align: center;
}

.agreementBox1 {
    width: 100%;
    height: 400px;
    display: flex;
    overflow-y: scroll;
}

.agreementGrid1 {
min-height: 80vh;
}

.agreementGrid2 {
    height: 60vh;
}