.adminButton {
    position: absolute !important ;
    margin: auto !important;
    text-transform: none !important;
    background-color: #101e35 !important;
    color: white !important
}

.adminOnDisableButton {
    position: absolute !important ;
    margin: auto !important;
    text-transform: none !important;
    background-color: rgba(0,0,0,0.2) !important;
    color: white !important
}

.adminDiv {
    padding-top: 5%;
}