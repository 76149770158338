#head {
    margin-top: 0px;
    height: 100vh;
}

.head_img img {
    width: 100%;
}

.head_contentError {
    padding: 25px 0px;
    margin-top: 35vh;
}

.head_content h1 {
    font-weight: bolder;
    width:150%
}

.head_contentError h2 {
    font-weight: bolder;
    width:120%;
}

.head_btn a {
    border-radius: 50px;
    padding: 5px 20px;
    color: white !important;
    border-color: #1d1d36;
    background-color: #1d1d36;
}

.col-md-3 {
    padding-left: 0px;
}

.button1 {
    color: white !important;
    background-color: #101e35 !important;
    border-color: #101e35 !important;
    border: 2px #101e35;
    border-radius: 25px;
    width: max-content;
    padding: 8px 20px;
}

.col-md-6 {
    padding-right: 0px;
    margin-top: 20px;
}