
  .form-control {
    height: 38px !important;
    width: 100% !important;
    border: 0px !important;
    box-shadow: none !important;
  }
  .react-tel-input {
    height: 40px !important;
    width: 84% !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
  }
  
  .flag-dropdown {
    border: 0px !important;
  }

  input[type="checkbox"][readonly] {
  pointer-events: none;
}

.createProfileCheckBox {
  width: 17px !important;
  height: 17px !important;
  margin: 9px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
}

.createProfileForCol {
  margin-right: 60px !important;
}

.textFieldStyle {
  width: 85% !important;
}

.imageGrid {
  height: 100% !important
}

.logoImg {
  height: 75vh !important;
  padding-left: 10% !important;
}

.mobileGrid {
  padding-top: 4% !important;
}

.GridContainer {
  min-height: 100% !important;
}

.GridContainerOne {
  height: 70vh !important;
}

.CreateProfilePaper {
  width: 90% !important;
  height: 100% !important;
  display: flex !important;
}

.CreateProfileDivOne {
  display: flex;
  width: 100%;
  margin-right: 6%;
}

.UserRoleHelper{
  font-size: 14px;
  color: red;
}