.boxShadow {
    box-shadow: none !important;
}

.rowStyleForProfile {
    width: 200% !important;
    margin-left: 200% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 10px !important;
    margin-bottom: 5px !important;
}

.spanStyleForProfile {
    width: 10% !important;
}

.textFieldStyleForProfile {
    width: 40% !important;
}

.marginForRow {
    margin-left: 2% !important;
}

.marginTop {
    margin-top: 2% !important;
}

.profilePaper {
    width: 185% !important;
    height: 72vh !important;
    margin-left: 1% !important;
}

.profilePaperForAdmin {
    width: 500% !important;
    height: 72vh !important;
    margin-left: 1% !important; 
}
.profileLeft {
    margin-left: 3.5% !important;
}

.profileTextBoxWidth {
    width: 95% !important;
}

.email {
    margin-left: 2% !important;
    margin-top: 5% !important;
}

.boxForDetails {
    width: 100% !important;
    padding-bottom: 10% !important;
}

.MuiAvatar-square {
    margin-left: 5%;
}

.bottomSpace {
    margin-bottom: 10%;
}