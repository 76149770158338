#head {
    margin-top: 0px;
    height: 100vh;
}

.head_img img {
    width: 100%;
}

.head_content {
    width: 100%;
    padding: 25px 0px;
}

.head_content h2 {
    font-weight: bolder;
}

.head_btn a {
    border-radius: 50px;
    padding: 10px 20px;
}

.button1 {
    color: white !important;
    background-color: #101e35 !important;
    border-color: #101e35 !important;
    border: 2px #101e35;
    border-radius: 25px;
    width: max-content;
    padding: 8px 20px;
}

.button2 {
    color: white !important;
    background-color: #101e35 !important;
    border-color: #101e35 !important;
    margin-left: 30px !important;
    border: 2px #101e35;
    border-radius: 25px;
    width: max-content;
    padding: 8px 20px;
}