.delete {
    /* margin-bottom: 46px; */
    /* margin: 0; */
    padding: 0;
    margin-bottom: 30px;

}

label,
input {
    display: inline-block;
    vertical-align: middle;
}

.FilledBackColor {
    background-color: #C3CED5;
}