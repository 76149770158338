#bottom {
    margin-bottom: 2%;
}

.wdithForVerify {
    width: 100% !important;
}

.dialogForVerify {
    width: 30%;
    margin-left: 35%;
    margin-bottom: 5%;
}

.paddingBottomForVerify {
padding-bottom: 0%;
}

.paddingForVerify {
    padding: 2% 18%;
}

.dialogButtontoVerify {
    display: flex;
    margin-top: 6%;
    justify-content: end;
    margin-right: 5%;
}