.form-control {
  height: 38px !important;
  width: 100% !important;
  border: 0px !important;
  box-shadow: none !important;
}
.react-tel-input {
  height: 40px !important;
  width: 84% !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  box-shadow: none !important;
}

.flag-dropdown {
  border: 0px !important;
}

.companyDiv {
height: 20%;
}

.companyPaddingleft {
padding-left: 25px !important;
margin-top: 5px !important;
}

.formWidth {
width: 60% !important;
}

.paddigAndHeight{
padding-top: 5%;
/* height: 5px; */
}

.tableHeaderSize {
font-size: 15px !important;
}

.SaveButtonSpacing {
padding-left: 20% !important;
padding-top: 6%;
}

/* User Details */
.userTableWidth {
width: 1%;
}

.textfieldForTable {
width: 85%;
}

/* Invite User */
.invitePaddingLeft {
padding-left: 50px;
font-size: 20px;
}

.invite1stGrid {
margin-right: 60px;
}

.inviteCard {
max-width: 80% !important;
margin: 0, auto !important;
padding-top: 20px !important;
padding-bottom: 20px !important;
}

.paddingAndWidth {
width: 60%;
padding-bottom: 10px;
}

.invitePaneBox {
  width: 100%;
}

.newUserPaneBox {
  width: 100%;
  max-height: 112%;
  height: 112%;
}

.userDetailsPaneBox {
  width: 100%;
  margin-bottom: 20px;
}
/**/
.paneWidth {
width: 54%;
padding-bottom: 5%;

}

.paddingforGrid {
padding-top: 3%;
}