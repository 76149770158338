.commonButton {
text-transform: none !important;
color: white !important;
background-color: #101e35 !important;
justify-content: center !important;
width: max-content;
}

.commonDisableButton {
text-transform: none !important;
color: white !important;
background-color: #9e9e9ec2 !important
}
.commonDiv {
    width:100% !important;
    height: 10px  !important
}
.commonBox {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;
}

.commonPaper {
    width: 100%;
    height: 100%;
    display: flex;
}

.commonh2 {
    display: flex;
    justify-content: center;
    color: white;
}

/*Verification and suspended */
.verficationAvatar {
    width: 10vh !important;
    height: 10vh !important;
}

.verificationPaper {
    width: 70%;
    height: 95%;
    display: flex;
}

.minHeight {
    min-height: 100%;
}

.height {
    height: 100%;
}